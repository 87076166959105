import { partition } from 'lodash';
import { IAnalysisRule } from 'shared/models/Analysis-Document';

export const sortCategoriesAlphabetically = <T extends { category: string }>(a: T, b: T) =>
  a.category.localeCompare(b.category);

type RuleGroup = { name: string; rules: IAnalysisRule[]; groupIndex: number };
type RuleOrGroup = IAnalysisRule | RuleGroup;

export const prependSeq0Rule = (groups: RuleGroup[], singles: IAnalysisRule[]): RuleOrGroup[] => {
  const [_singles, seq0Rule] = partition(singles, (rule) => rule.seq !== 0);
  const allItems = [...groups, ..._singles];

  if (seq0Rule.length) {
    allItems.unshift(seq0Rule[0]);
  }

  return allItems;
};

// export const getCategoryCountUpdated = (
//   isReviewed: boolean,
//   dataToUpdate: IAnalysisCategory[],
//   category: IAnalysisCategory['category'],
//   documentId: ICategoryDocument['documentId']
// ) => {
//   return dataToUpdate.map((cat) => {
//     if (cat.category === category) {
//       // if reviewed then decrement, if !reviewed then increment
//       const newCount = isReviewed ? -1 : 1;
//
//       return {
//         ...cat,
//         rulesToReview: cat.rulesToReview + newCount,
//         differentiators: cat.differentiators.map((diff) => {
//           return {
//             ...diff,
//             documents: diff.documents.map((doc) => {
//               if (doc.documentId === documentId) {
//                 return {
//                   ...doc,
//                   rulesToReview: doc.rulesToReview + newCount
//                 };
//               }
//
//               return doc;
//             })
//           };
//         })
//       };
//     }
//
//     return cat;
//   });
// };
