import { ValidationsV3APIDecision, ValidationsV3APIStatus, ValidationsV3APISummary } from 'shared/models/ValidationsV3';
import { SaleEligibilityIconState } from './types';

export const getLqaSaleEligibilityIconState = (
  validationRecord: ValidationsV3APISummary | null | undefined
): SaleEligibilityIconState => {
  if (validationRecord === undefined) {
    // No record exists because no fetches were made
    // Use a loading state, which UX usages interpret as needing to start a fetch call
    return SaleEligibilityIconState.Loading;
  } else if (validationRecord === null) {
    // A null record is stored if the fetch completes but fails
    // Gray represents error
    return SaleEligibilityIconState.Gray;
  } else if (!validationRecord.lqa) {
    // HD-6859: Fetch succeeded, but no LQA result is available yet
    return SaleEligibilityIconState.Processing;
  } else {
    switch (validationRecord.lqa.status) {
      case ValidationsV3APIStatus.PRECHECK_ERROR:
        // Precheck errors mean BE does not submit to the LQA service because missing fields would guarantee a Red status
        return SaleEligibilityIconState.Red;
      case ValidationsV3APIStatus.COMPLETE:
        switch (validationRecord.lqa.decision) {
          case ValidationsV3APIDecision.RED:
            return SaleEligibilityIconState.Red;
          case ValidationsV3APIDecision.YELLOW:
            return SaleEligibilityIconState.Orange;
          case ValidationsV3APIDecision.GREEN:
            return SaleEligibilityIconState.Green;
          case ValidationsV3APIDecision.UNKNOWN:
          default:
            // Fallback to an error (represented by a gray icon)
            return SaleEligibilityIconState.Gray;
        }
      // HD-6859: Pending status is not expected to be returned
      case ValidationsV3APIStatus.ERROR:
      case ValidationsV3APIStatus.PENDING:
      case ValidationsV3APIStatus.UNEXPECTED:
      default:
        // Fallback to an error (represented by a gray icon)
        return SaleEligibilityIconState.Gray;
    }
  }
};
