import { createSelector } from 'reselect';
import { RootState } from '../../store';

// @deprecated TODO: Remove usages of v1 validations API selector
export const selectLQAStatuses = (state: RootState) => state.statuses.lqa;
export const selectValidations = (state: RootState) => state.statuses.validations;

// @deprecated TODO: Remove usages of v1 validations API selector
export const makeSelectLQAStatusesByMortgageId = () =>
  createSelector(
    selectLQAStatuses,
    (_: RootState, mortgageId: string) => mortgageId,
    (statuses, mortgageId) => statuses[mortgageId]
  );

export const makeSelectValidationsByMortgageId = () =>
  createSelector(
    selectValidations,
    (_: RootState, mortgageId: string) => mortgageId,
    (validations, mortgageId) => validations[mortgageId]
  );
