import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusesSlice } from './types';

const initialState: StatusesSlice = {
  lqa: {},
  validations: {}
};

const statusesSlice = createSlice({
  name: 'statuses',
  initialState,
  reducers: {
    appendToLQAStatuses: (state, action: PayloadAction<StatusesSlice['lqa']>) => {
      state.lqa = { ...state.lqa, ...action.payload };
    },
    appendToValidations: (state, action: PayloadAction<StatusesSlice['validations']>) => {
      state.validations = { ...state.validations, ...action.payload };
    }
  }
});

export const { appendToLQAStatuses, appendToValidations } = statusesSlice.actions;
export default statusesSlice.reducer;
