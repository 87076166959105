import Big, { BigSource } from 'big.js';
import moment from 'moment';

// Customize the relative time formatting
moment.updateLocale('en', {
  relativeTime: {
    future: 'In %s',
    d: '1 day',
    w: '1 week',
    M: '1 month',
    y: '1 year'
  }
});

export const usCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
});

// Only use "testOptions" for testing purposes
export const shortNameDateTimeFormat = (
  dateString?: number | string | Date,
  testOptions: Intl.DateTimeFormatOptions = {}
): string => {
  if (!dateString) return '';

  return new Date(dateString).toLocaleDateString('default', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    ...testOptions
  });
};

export const calcDateTimeFromToday = (dateString: string | Date) => {
  // Use startOf to ensure we're comparing by days and not hours
  const date = moment(dateString).startOf('day');
  const today = moment().startOf('day');

  if (date.isSame(today, 'day')) {
    return 'Today';
  }

  return date.from(today);
};

export const formatTimeDisplay = (isoTDate: string) => moment(isoTDate).format('MMM DD');

export const bidPriceCalculation = (basisPoints: BigSource): Big => {
  const basePercentage = new Big(100);
  const basisPointsToPercentage = new Big(basisPoints).div(100).toFixed(2);

  return basePercentage.plus(basisPointsToPercentage);
};

export const displayAsPercentage = (percent: Big) => {
  return `${percent.toFixed(2)}`;
};

export function convertCamelCasetoTitleCase(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const formatLargeNumber = (_num: number | string): string => {
  const bigNum = new Big(_num);
  const language = 'en-US';
  const styleOptions = { style: 'decimal' };
  const options = { ...styleOptions, maximumFractionDigits: 1 };
  const billions = 1e9;
  const millions = 1e6;
  const thousands = 1e3;

  if (bigNum.gte(billions)) {
    return new Intl.NumberFormat(language, options).format(bigNum.div(billions).toNumber()) + 'B';
  } else if (bigNum.gte(millions)) {
    return new Intl.NumberFormat(language, options).format(bigNum.div(millions).toNumber()) + 'M';
  } else if (bigNum.gte(thousands)) {
    return (
      new Intl.NumberFormat(language, { ...styleOptions, maximumFractionDigits: 0 }).format(
        bigNum.div(thousands).toNumber()
      ) + 'K'
    );
  }

  return new Intl.NumberFormat(language).format(bigNum.toNumber());
};
