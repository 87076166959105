import type { IBulkGroupLoan, IBulkLoan, IBulkLoansGroup } from 'shared/models/Loan';
import type { IMortgage } from 'shared/models/Mortgage';
import { ValidationsV3APISummary } from 'shared/models/ValidationsV3';

export enum LQAMarketplaceStatus {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Error = 'error'
}

export enum SaleEligibilityIconState {
  // For the secondary sorted enum to stay valid, enum value strings must exactly match their keys
  Red = 'Red',
  // TODO: Change this Orange name to Yellow to better match existing discussed terminology
  Orange = 'Orange',
  Green = 'Green',
  // This is used for an error state
  Gray = 'Gray',
  Loading = 'Loading',
  Processing = 'Processing'
}

export enum SaleEligibilityIconStateSorted {
  Red = 0,
  Orange = 1,
  Green = 2,
  Gray = 3,
  Loading = 4,
  // Processing is sorted last, because a Processing status can take many minutes to change
  Processing = 5
}

export type IBulkLoanWithSaleEligibilityIcon = IBulkLoan & {
  saleEligibilityIconState: SaleEligibilityIconState;
};

export type IBulkGroupLoanWithSaleEligibilityIcon = IBulkGroupLoan & {
  saleEligibilityIconState: SaleEligibilityIconState;
};

export type IBulkLoansGroupWithSaleEligibilityIcon = IBulkLoansGroup & {
  loans: IBulkGroupLoanWithSaleEligibilityIcon[];
};

export type StatusesSlice = {
  // A null LQA status for a mortgage represents a successful fetch with no validation result
  lqa: Record<IMortgage['internalId'], LQAMarketplaceStatus | null>;

  // A null validations status for a mortgage occurs when the fetch fails
  validations: Record<IMortgage['internalId'], ValidationsV3APISummary | null>;
};
