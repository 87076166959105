import { RootState, store } from '../../store';
import { ContractStatus, IBulkLoan, IBulkLoansGroup } from 'shared/models/Loan';
import { createSelector, createSelectorCreator, lruMemoize } from 'reselect';
import Big from 'big.js';
import { INITIAL_BULK_CONTRACT_STATUS } from './bulk.constants';
import {
  IBulkLoansGroupWithSaleEligibilityIcon,
  IBulkLoanWithSaleEligibilityIcon,
  SaleEligibilityIconState
} from '../statuses/types';
import { selectValidations } from '../statuses/statuses.selectors';
import { getLqaSaleEligibilityIconState } from '../statuses/statuses.utils';
import { keyBy } from 'lodash';
import { selectHasOpenMortgages, selectOpenMortgages } from '../mortgage/mortgage.selectors';
import { IMortgageFlat } from 'shared/models/Mortgage';
import { addSaleEligibilityIconToContractLoans } from './bulk.utils';
import { BulkStep } from './types';

// This equality function ignores non-loan number changes (ignores changes in other loan fields like interest rate, firm, etc)
const areLoansLoanNumbersEqual = (loansA: IBulkLoan[], loansB: IBulkLoan[]) => {
  if (loansA === loansB) return true;
  if (loansA?.length !== loansB?.length) return false;
  return loansA.every((loan, i) => loan?.loanNumber === loansB[i]?.loanNumber);
};
// Only compares loan numbers, not all loan fields
const createLoanNumberEqualSelector = createSelectorCreator(lruMemoize, areLoansLoanNumbersEqual);

export const getIsContractCompleted = (contract: IBulkLoansGroup) =>
  Boolean(contract.status) && contract.apiStatus && contract.apiStatus !== INITIAL_BULK_CONTRACT_STATUS;

export const getIsContractInProgress = (contract: IBulkLoansGroup) =>
  contract.apiStatus === INITIAL_BULK_CONTRACT_STATUS;

export const selectBulk = (state: RootState) => state.bulk;
export const selectBulkLoans = (state: RootState) => state.bulk.importedSourceLoans;

// Returns a stable output reference as long as loan numbers don't change
const selectBulkLoansLoanNumbers = createLoanNumberEqualSelector(selectBulkLoans, (loans) =>
  loans.map((loan) => loan.loanNumber)
);

export const selectBulkLoansLoanNumbersToMortgageIds = createSelector(
  selectBulkLoansLoanNumbers,
  // Recompute if mortgages finish fetching (count changes from 0 to non-zero)
  // TODO: Do we need to handle the case where a mortgage is moved from Pipeline to Bids, and then added to Prime Commit
  selectHasOpenMortgages,
  (loanNumbers, hasOpenMortgages): Record<IBulkLoan['loanNumber'], IMortgageFlat['internalId']> => {
    if (!hasOpenMortgages || loanNumbers.length === 0) {
      return {};
    }

    // Extract from state directly instead of using selectOpenMortgages
    // Because using selectOpenMortgages as a selector dependency causes any mortgage fetches to recompute this selector
    const state = store.getState();
    const mortgagesByLoanNumber = keyBy(selectOpenMortgages(state), 'loanNumber');

    return loanNumbers.reduce<Record<IBulkLoan['loanNumber'], IMortgageFlat['internalId']>>((accum, loanNumber) => {
      const mortgage = mortgagesByLoanNumber[loanNumber];

      if (mortgage) {
        accum[loanNumber] = mortgage.internalId;
      }
      return accum;
    }, {});
  }
);

export const selectBulkLoansWithSaleEligibilityIcon = createSelector(
  selectBulkLoans,
  selectBulkLoansLoanNumbersToMortgageIds,
  selectValidations,
  (bulkLoans, bulkLoanNumbersToMortgageIds, validations): IBulkLoanWithSaleEligibilityIcon[] =>
    bulkLoans.map((loanWithoutStatus) => {
      const mortgageId = bulkLoanNumbersToMortgageIds[loanWithoutStatus.loanNumber];
      const saleEligibilityIconState = mortgageId
        ? getLqaSaleEligibilityIconState(validations[mortgageId])
        : // For invalid loan numbers on step 1 prime commit, display a placeholder status because user must correct validation error
          SaleEligibilityIconState.Processing;

      return {
        ...loanWithoutStatus,
        saleEligibilityIconState
      };
    })
);
export const selectBulkTrades = (state: RootState) => state.bulk.importedSourceTrades;
export const selectBulkContracts = (state: RootState) => state.bulk.importedContractData;

export const selectBulkContractsLoanNumbersToMortgageIds = createSelector(selectBulkContracts, (contracts) =>
  contracts.reduce<Record<IBulkLoan['loanNumber'], IMortgageFlat['internalId']>>(
    (loanNumbersToMortgageIds, contract) => {
      contract.loans.forEach((loan) => (loanNumbersToMortgageIds[loan.loanNumber] = loan._internalMortgageId));
      return loanNumbersToMortgageIds;
    },
    {}
  )
);
export const selectBulkContractsWithLoansSaleEligibilityIcon = createSelector(
  selectBulkContracts,
  selectValidations,
  (importedContractData, validations): IBulkLoansGroupWithSaleEligibilityIcon[] =>
    importedContractData.map((contract) => addSaleEligibilityIconToContractLoans(contract, validations))
);

export const selectBulkLoanErrors = (state: RootState) => state.bulk.importedSourceLoansErrors;

export const selectBulkTradesErrors = (state: RootState) => state.bulk.importedSourceTradesErrors;

export const selectBulkContractsCount = (state: RootState) => state.bulk.importedContractData.length;

export const selectCurrentStep = (state: RootState) => state.bulk.currentStep;

export const selectSelectorForBulkStepLoanNumbersToMortgageIds = createSelector(selectCurrentStep, (currentStep) =>
  // Return a step-specific selector function instead of running both selectors, because it's expensive to run
  currentStep === BulkStep.UPLOAD_LOANS
    ? selectBulkLoansLoanNumbersToMortgageIds
    : // After the first step, grouped contracts contain mortgage IDs
      selectBulkContractsLoanNumbersToMortgageIds
);

export const selectIsTransactionInProgress = createSelector(selectBulkContracts, (contracts) =>
  contracts.some(getIsContractInProgress)
);

export const selectIsBulkCompleted = createSelector(selectBulkContracts, (contracts) =>
  contracts.every(getIsContractCompleted)
);

export const selectBulkContractsTotalAmount = createSelector(selectBulkContracts, (contracts) =>
  contracts.reduce((totalAmount, contract) => totalAmount.plus(contract.contractAmount), new Big(0)).toFixed(2)
);

export const selectSuccessContracts = createSelector(selectBulkContracts, (contracts) =>
  contracts.filter((contract) => contract.status === ContractStatus.SUCCESS)
);

export const selectNotFailedContracts = createSelector(
  selectBulkContracts,
  selectValidations,
  (contracts, validations): IBulkLoansGroupWithSaleEligibilityIcon[] =>
    contracts
      .filter((contract) => contract.status !== ContractStatus.FAILURE)
      .map((contract) => addSaleEligibilityIconToContractLoans(contract, validations))
);

export const selectFailureContracts = createSelector(
  selectBulkContracts,
  selectValidations,
  (contracts, validations): IBulkLoansGroupWithSaleEligibilityIcon[] =>
    contracts
      .filter((contract) => contract.status === ContractStatus.FAILURE)
      .map((contract) => addSaleEligibilityIconToContractLoans(contract, validations))
);

export const selectSpreadsheetLoansErrorMessage = (state: RootState) => state.bulk.spreadsheetLoansImportError;
export const selectSpreadsheetTradesErrorMessage = (state: RootState) => state.bulk.spreadsheetTradesImportError;

export const selectManageAotLoanNumberFilter = (state: RootState) => state.bulk.manageAotLoanNumberFilter;
