import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import './index.scss';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

const INSTANT_OPEN_DURATION = 0;

interface TooltipProps {
  content: string;
  contentClassName?: string;
  showArrow?: boolean;
  delayDuration?: number;
  disableHoverableContent?: boolean;
  side?: 'top' | 'right' | 'bottom' | 'left' | undefined;
  align?: 'start' | 'center' | 'end' | undefined;
  portal?: boolean;
  sideOffset?: number;
}

const TooltipContent = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ content, contentClassName: className, showArrow, side, align, sideOffset }, ref) => {
    return (
      <TooltipPrimitive.Content
        ref={ref}
        side={side}
        align={align}
        className={classNames('TooltipContent', className)}
        sideOffset={sideOffset}
      >
        {content}
        {showArrow && <TooltipPrimitive.Arrow width={11} height={5} className="TooltipArrow" />}
      </TooltipPrimitive.Content>
    );
  }
);

// Display name is for ESLint rule
TooltipContent.displayName = 'TooltipContent';

const Tooltip = ({
  children,
  content,
  contentClassName: className,
  showArrow = false,
  side = 'bottom',
  align = 'center',
  delayDuration = INSTANT_OPEN_DURATION,
  disableHoverableContent = false,
  portal = true,
  sideOffset = 5
}: PropsWithChildren<TooltipProps>) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={delayDuration} disableHoverableContent={disableHoverableContent}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {portal ? (
          <TooltipPrimitive.Portal>
            <TooltipContent
              content={content}
              contentClassName={className}
              showArrow={showArrow}
              side={side}
              align={align}
              sideOffset={sideOffset}
            />
          </TooltipPrimitive.Portal>
        ) : (
          <TooltipContent content={content} showArrow={showArrow} side={side} align={align} sideOffset={sideOffset} />
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
