import { ColDef } from 'ag-grid-community';
import { IBulkGroupLoan, IBulkLoansGroup } from '../../../models/Loan';
import { CommitContractError } from '../../../models/Bulk';
import { IBulkGroupLoanWithSaleEligibilityIcon } from 'state-management/slices/statuses/types';

export const BULK_COMMIT_API_ERROR_TO_MSG: Record<CommitContractError, string> = {
  [CommitContractError.MORTGAGE_IN_BULK]:
    'One or more mortgages included in this contract are already included in another contract commitment.',
  [CommitContractError.MORTGAGE_ACCEPTED]:
    'One or more mortgages included in this commitment have already been committed',
  [CommitContractError.LEDGER_ERROR]: 'Submission failed due to connectivity issues. Try again.',
  [CommitContractError.MISSING_BID]: 'One or more mortgages has not been bid on by the specified investor',
  [CommitContractError.MISSING_PAYUP]: 'Specified payup does not exist for one or more mortgages in the contract',
  [CommitContractError.FANNIE_COMMITMENT_FEATURE_IS_DISABLED]:
    'Feature is not enabled. Please contact Novaprime to enable.'
};

export const DEFAULT_BULK_COMMIT_ERROR: string = BULK_COMMIT_API_ERROR_TO_MSG[CommitContractError.LEDGER_ERROR];

export const containerStyle = { width: '100%', height: '100%' };
export const gridStyle = { height: '100%', width: '100%' };

export const baseColWidths = { minWidth: 90, maxWidth: 90 };
export const loanNumberColWidths = { minWidth: 188 };
export const bulkIconColWidths = { minWidth: 70, maxWidth: 70 };
export const interestRateColWidths = { minWidth: 130, maxWidth: 130 };
export const contractAmountColWidths = { minWidth: 125, maxWidth: 125 };
export const investorIdColWidths = { minWidth: 90 };
export const servicerFirmColWidths = { minWidth: 115 };
export const statusColWidths = { minWidth: 15, maxWidth: 15 };
export const resubmitBtnColWidths = { minWidth: 86, maxWidth: 86 };

export const specPoolCol: ColDef<IBulkGroupLoan | IBulkLoansGroup | IBulkGroupLoanWithSaleEligibilityIcon> = {
  field: 'specPool',
  minWidth: 90,
  cellClass: 'ag-right-aligned-cell ag-ellipses-cell ag-set-height-29'
};
